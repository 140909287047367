@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v20/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v20/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@import './tailwind-compiled';
@import './img-helpers';

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v20/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

:root {
  --ios-gray: #eaeaea;
  --flayr-primary: #bc9cff;
  --flayr-primary-alt: #8ba4f9;
  --flayr-gradient-primary: linear-gradient(
    180deg,
    var(--flayr-primary) 0%,
    var(--flayr-primary-alt) 100%
  );
  --flayr-gradient-text-primary: -webkit-linear-gradient(
    180deg,
    var(--flayr-primary) 0%,
    var(--flayr-primary-alt) 100%
  );

  --color-primary: #007aff;

  --user-theme-color-primary: #007aff;
  --user-theme-color-heading: black;
  --user-theme-font-heading: system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --user-theme-font-heading-url: '';

  --user-theme-font-body: system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --user-theme-font-body-url: '';
}

body {
  font-family: var(--user-theme-font-body);
}

.font-quicksand {
  font-family: Quicksand, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --user-theme-font-body: Quicksand, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.bg-ios-gray {
  background: var(--ios-gray);
}

.text {
  &-ios-gray {
    color: var(--ios-gray);
  }
  &-flayr-gradient-primary {
    color: var(--flayr-primary);
    background: var(--flayr-gradient-text-primary);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-primary {
    color: var(--user-theme-color-primary);
  }

  &-heading {
    color: var(--user-theme-color-heading);
  }
}

.bg {
  &-glass {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
  }

  &-primary {
    background-color: var(--user-theme-color-primary);
  }
}

@for $num from 1 through 5 {
  .backdrop-blur-#{$num * 10} {
    backdrop-filter: blur($num * 10px);
  }
}

.star {
  display: inline-block;
  &:before {
    color: currentColor;
    content: '\2605';
    display: inline;
  }
  &-outline {
    &:before {
      color: currentColor;
      content: '☆';
      display: inline;
    }
  }
}

// google autocomplete
.pac-container {
  z-index: 12000;
}

// cogotoast
#ct-container {
  z-index: 2147483647 !important;
}

// override
.actions-group {
  background: white;
}

.stroke-current {
  path {
    stroke: currentColor;
  }
}

.custom-nav-swiper {
  .swiper {
    &-wrapper {
      align-items: center;
    }
    &-button {
      &-next,
      &-prev {
        width: 48px;
        height: 48px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.09);
        border: 1px solid rgba(gray, 0.05);
        &:after {
          color: rgb(37, 37, 37);
          font-size: 1.5rem;
        }
        &.swiper-button-disabled {
          display: none;
          &:after {
            opacity: 0.35;
          }
        }
      }
    }

    &-scrollbar-drag {
      background: var(--user-theme-color-primary);
    }
  }
}

.checkbox {
  &-white {
    .icon-checkbox {
      border-color: white;
      background-color: rgba($color: white, $alpha: 0.3);
    }
  }

  &-shadow {
    .icon-checkbox {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
}

// helpers
.min-h-screen-1\/2 {
  min-height: 50vh;
}
